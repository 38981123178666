import '../Work.css';
import Nbar from "../components/Nbar";



import Image from 'react-bootstrap/Image';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import { Fade} from "react-awesome-reveal";



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faPenToSquare, faSquareCheck, faLightbulb } from '@fortawesome/free-regular-svg-icons'
import {faPenNib, faRepeat} from '@fortawesome/free-solid-svg-icons'





function Vital() {
    return (
        <div className="projectPage">

            <Stack direction="vertical" gap={4}>
            <Fade>


                <Nbar />
                <div className='card-container rounded-corner text-center'>
                    <p className='text-uppercase fw-semibold secondaryColor '>Vital Fitness</p>
                    <span className='display-2 fw-medium '>Reimagining at-home fitness for the visually impaired.</span>

                    <Stack direction="horizontal" gap={2} className='badge-container justify-content-center mt-2'>
                        <Badge pill bg="" className='fw-medium custom-badge-outline'>
                            User Research
                        </Badge>
                        <Badge pill bg="" className='fw-medium custom-badge-outline'>
                            UI Design
                        </Badge>
                        <Badge pill bg="" className='fw-medium custom-badge-outline'>
                            Branding
                        </Badge>

                    </Stack>





                    <Image src={process.env.PUBLIC_URL + "/images/thumb-vital.jpg"} className='rounded-corner-inner mt-3'alt='' />
                </div>


                <div className='card-container rounded-corner'>
                    <p className='text-uppercase fw-semibold'>Overview</p>
                    <p>This project was completed for <a href="https://iterateux.com/" className="styled-link" target="_blank" rel="noreferrer">IterateUX's</a> UX Design Challenge, a one-month challenge during which we conducted user research, defined a product, and prototyped its design while receiving mentorship from established product designers. We ultimately <span className='fw-semibold'>won first place out of 27 competing teams!</span><br></br><br></br>

                        We were prompted to design a mobile/smartwatch app that makes staying healthy and fit more accessible, fun and useful for people with disabilities. We decided to choose <span className='fw-semibold'>visually impaired individuals</span> as our user group because it would challenge us to understand an often neglected group of people through deep research to create an effective solution.</p>


                    <div className='d-flex justify-content-between flex-wrap pt-4 gap-5'>
                        <div>
                            <p className='text-uppercase fw-semibold'>Team</p>
                            <ul>
                                <li>Leah Churchwad</li>
                                <li>Suraj Mandal</li>
                                <li>Shilpa Ramachandran</li>
                                <li>Sarah Woo</li>
                            </ul>
                        </div>
                        <div>
                            <p className='text-uppercase fw-semibold'>Skills</p>
                            <ul>
                                <li>User research</li>
                                <li>Competitor analysis</li>
                                <li>UI design</li>
                            </ul>
                        </div>

                        <div>
                            <p className='text-uppercase fw-semibold'>Tools</p>
                            <ul>
                                <li>Miro</li>
                                <li>Figma</li>
                                <li>Notion</li>
                            </ul>
                        </div>

                    </div>

                </div>
                <div className='card-container rounded-corner'>
                    <p className='text-uppercase fw-semibold secondaryColor'>Problem Statement</p>
                    <p className='display-2 '>People with visual impairments face accessibility barriers when engaging in fitness activities, putting their safety and health at risk.</p>

                    <p className='text-uppercase fw-semibold secondaryColor'>Objective</p>
                    <p className='display-2 '>Strategize and design a mobile fitness app to help visually impaired people reach their health goals in a safe and engaging manner.

                    </p>

                </div>
                <div className='card-container rounded-corner'>
                <h1 className='display-2 
                     text-center'>

                        The Final Solution

                        <FontAwesomeIcon icon={faStar} className='secondaryColor header-icon' style={{}} />
                    </h1>

                    <Image src={process.env.PUBLIC_URL + "/images/vital-final-case.png"} className='rounded-corner-inner mt-4' style={{ alignSelf: 'start' }} alt='' />


                </div>

                <div className='card-container rounded-corner'>
                    <h1 className='display-2 
                     text-center'>


                        Phase 01: Research

                        <FontAwesomeIcon icon={faPenToSquare} className='secondaryColor header-icon' style={{}} />
                    </h1>

                    <p className='mt-4'>We recognized that the research stage was crucial for our project, since we were designing for individuals with a disability that we had never experienced. So, we decided to pursue multiple avenues of research, including <span className="fw-semibold"> secondary research, user interviews, and competitor analyses</span> to ensure that we were as well-informed as possible.
                    </p>

                    <p className='text-uppercase fw-semibold secondaryColor mt-5'>Secondary Research</p>
                    <p>Before starting our user interviews, we conducted secondary research to gain some initial knowledge and ensure that we came from a place of understanding. This also helped us gauge what further information we'd need to obtain from other forms of research down the line.</p>
                    <img src={process.env.PUBLIC_URL + "/images/vital-secondary.png"} alt="" className="rounded-corner-inner shrink-width mx-auto mt-4"></img>

                    <p className='text-uppercase fw-semibold secondaryColor mt-5'>User Interviews</p>

                    <p>We found our interviewees by posting in Facebook groups and online forums related to visual impairment. We found that members were quite eager to contribute to our project and were able to recruit 5 participants, ranging in age and level of visual impairment.</p>


                    <img src={process.env.PUBLIC_URL + "/images/vital-interview-stats.png"} alt="" className="shrink-width mx-auto mt-5"></img>

                    <p className='mt-5'>There were <span className="fw-semibold">4 main objectives</span> that we used to guide and narrow the scope of our interview questions:

                        find out how participants normally exercise, uncover the tools participants use to engage in fitness, identify the obstacles participants face when setting and achieving fitness goals, and identify obstacles participants run into when using technology.</p>
                    <p>Following the interviews, we were left with an overwhelming amount of information and decided to use affinity mapping to synthesize them into broader, actionable categories:</p>


                    <img src={process.env.PUBLIC_URL + "/images/vital-interviews-affinity.png"} alt="" className="rounded-corner-inner mx-auto mt-5"></img>

                    <p className='mt-4'>
                        We boiled down these organized insights to <span className='fw-semibold'>3 key takeaways:</span>
                    </p>

                    <div className="flex-list">

                        <div className='flex-list-item'>
                            <div className='circle-number'>1</div>
                            <span>Using outdoors facilities is frustrating and sometimes hazardous without the help of a sighted person.</span>
                        </div>
                        <div className='flex-list-item'>
                            <div className='circle-number'>2</div>
                            <span>Keeping track of goals and progress is a big priority.</span>
                        </div>
                        <div className='flex-list-item'>
                            <div className='circle-number'>3</div>
                            <span>AI and technological advancements have significantly improved their lives by making things more accessible.</span>
                        </div>
                    </div>

                    <p className='text-uppercase fw-semibold secondaryColor mt-5'>Competitor Analysis</p>

                    <p>

                        To better understand the current fitness options that exist for visually impaired people, we performed a competitor analyses of 4 products, including <a href="https://www.revisionfitnessapp.com/" className="">ReVision Fitness</a> and <a href="https://aaptiv.com/" className="">Aaptiv</a>, and a comparative analysis of <a href="https://www.bemyeyes.com/" className="">Be My Eyes</a>.</p>


                    <img src={process.env.PUBLIC_URL + "/images/vital-competitor-analysis.png"} alt="" className="mx-auto mt-4"></img>


                    <p className='mt-5'>
                        We boiled down these insights to <span className='fw-semibold'>5 key takeaways:</span></p>
                    <div className="flex-list">

                        <div className='flex-list-item'>
                            <div className='circle-number'>1</div>
                            <span>Apps should provide precise control over users’ fitness experience.</span>
                        </div>
                        <div className='flex-list-item'>
                            <div className='circle-number'>2</div>
                            <span>Community and social features can increase engagement.</span>
                        </div>
                        <div className='flex-list-item'>
                            <div className='circle-number'>3</div>
                            <span>Simple, straightforward UI is easiest to navigate for visually impaired people.</span>
                        </div>
                        <div className='flex-list-item'>
                            <div className='circle-number'>4</div>
                            <span>Fitness apps that provide extensive safety precautions do not exist.</span>
                        </div>
                        <div className='flex-list-item'>
                            <div className='circle-number'>5</div>
                            <span>Videos should be accompanied by audio transcriptions for screenreaders to interpret.</span>
                        </div>
                    </div>


                </div>
                <div className='card-container rounded-corner'>
                    <h1 className='display-2  text-center'>


                        Phase 02: Defining the Product

                        <FontAwesomeIcon icon={faSquareCheck} className='secondaryColor header-icon' style={{}} />
                    </h1>

                    <p className='mt-4'>
                    At this point, we were equipped with enough information to begin to define the scope of our solution. To do so, we decided to <span className='fw-semibold'>define a persona, define a clear problem statement and objective, and use priority mapping to narrow down the product's features</span>.
                    </p>

                    <p className='text-uppercase fw-semibold secondaryColor mt-5'>Our Persona</p>

                    <p>

                    Creating a persona for our target user helped us empathize with them and come up with a user-centric solution.</p>

                    <img src={process.env.PUBLIC_URL + "/images/vital-persona.png"} alt="" className="mt-4"></img>

                    <p className='text-uppercase fw-semibold secondaryColor mt-5'>Defining the Features</p>

                    <p>

                    Based on our clearly defined objective, we brainstormed the features that our product could offer. We found that there were a wide variety of ways that our problem statement could be addressed and needed a way to narrow down the overwhelming number of feature ideas we had. We used <span className='fw-semibold'>priority mapping with an impact-effort matrix</span> to analyze each idea and decide what should and shouldn't be included in our final product design.</p>

                    <img src={process.env.PUBLIC_URL + "/images/vital-priority-mapping.png"} alt="" className="rounded-corner-inner mt-4"></img>

                    <p className='mt-5'>Each feature that our product would provide could be categorized into the following 4 main categories:</p>
                        <img src={process.env.PUBLIC_URL + "/images/vital-features.png"} alt="" className="rounded-corner-inner mt-4"></img>



                </div>

                <div className='card-container rounded-corner'>
                    <h1 className='display-2  text-center'>


                        Phase 03: Prototyping

                        <FontAwesomeIcon icon={faPenNib} className='secondaryColor header-icon' style={{}} />
                    </h1>

                    <p className='text-uppercase fw-semibold secondaryColor mt-5'>Sketches to High Fidelity Prototyping</p>

                    <img src={process.env.PUBLIC_URL + "/images/vital-prototyping.png"} alt="" className="shrink-width mx-auto mt-4"></img>

                    <div className='lighter-bg mt-5'>
                    <h1 className="display-2 secondaryFont text-center">Customizability</h1>
                        <img src={process.env.PUBLIC_URL + "/images/vital-prototype-customizability.png"} alt="" className=''></img>


                        <h1 className="display-2 secondaryFont text-center mt-5">Accessibility</h1>
                        <img src={process.env.PUBLIC_URL + "/images/vital-prototype-accessibility.png"} alt="" className=''></img>


                        <h1 className="display-2 secondaryFont text-center mt-5">Trackability</h1>
                        <img src={process.env.PUBLIC_URL + "/images/vital-prototype-trackability.png"} alt="" className=''></img>


                        <h1 className="display-2 secondaryFont text-center mt-5">Safety</h1>
                        <img src={process.env.PUBLIC_URL + "/images/vital-prototype-safety.png"} alt="" className=''></img>


                    </div>
                    </div>

                    <div className='card-container rounded-corner'>
                    <h1 className='display-2  text-center'>


                        Phase 04: Refining

                        <FontAwesomeIcon icon={faRepeat} className='secondaryColor header-icon' style={{}} />
                    </h1>

                    <p className='text-uppercase fw-semibold secondaryColor mt-5'>Improving the Product's Visual Design</p>

                    <p>We had already won first place with our first iteration high-fidelity prototype, but I thought that our design choices (which had been made somewhat hastily in a time crunch) could be <span className='fw-semibold'>more intentional and accessibility-oriented</span>. In the weeks following the end of the IterateUX UX Design Challenge, I redesigned our product with an emphasis on clear communication, visibility, and intuitive placement of elements.</p>

                    <img src={process.env.PUBLIC_URL + "/images/vital-visual-design.png"} alt="" className="rounded-corner-inner mt-4"></img>



                    </div>

                    <div className='card-container rounded-corner'>
                    <h1 className='display-2  text-center'>


                        Key Takeaways

                        <FontAwesomeIcon icon={faLightbulb} className='secondaryColor header-icon' style={{}} />
                    </h1>

                    <p className='mt-4'><span className='fw-semibold '>Research, research, research!</span> In the case that a user group's experiences are completely foreign, research is essential in empathizing with them to truly understand what their needs and wants are. Deeper research allows for more effective design solutions.</p>
                        <p><span className='fw-semibold'>Designing for accessibility requires very intentional design choices.</span> Making visual design choices for normally sighted people often feels intuitive, and many of the choices made are based on trends or arbitrary preference. Designing for visually impaired people was an incredible challenge and a great reminder that choices should have intention and reason behind them.
                        </p>
                    </div>

                    </Fade>

            </Stack>
        </div>


    );
}

export default Vital;
import '../Work.css';
import Nbar from "../components/Nbar";


import Image from 'react-bootstrap/Image';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import { Fade} from "react-awesome-reveal";



function Remoov() {
    return (
        <div className="projectPage">

            <Stack direction="vertical" gap={4}>
                <Fade>

                <Nbar />
                <div className='card-container rounded-corner text-center'>
                    <p className='text-uppercase fw-semibold secondaryColor'>Remoov</p>
                    <span className='display-2 fw-medium '>Designing for a tech-enabled decluttering and reselling service.</span>

                    <Stack direction="horizontal" gap={2} className='badge-container justify-content-center mt-2'>
                        <Badge pill bg="" className='fw-medium custom-badge-outline'>
                            UI Design
                        </Badge>
                        <Badge pill bg="" className='fw-medium custom-badge-outline'>
                            Graphic Design
                        </Badge>

                    </Stack>


                    <Image src={process.env.PUBLIC_URL + "/images/thumb-remoov.png"} className='rounded-corner-inner mt-3' alt='' />


                </div>

                <div className='card-container rounded-corner'>
                    <p className='text-uppercase fw-semibold'>Overview</p>
                    <p><a href='https://remoovit.com/' className="styled-link" target="_blank" rel="noopener noreferrer">Remoov</a> is a pick-up and reselling service that eliminates the hassle of disposing of items for customers. They provide the means and expertise to make decluttering a simple process. In the process, they are able to reduce waste, increase item reuse, and provide customers with a fair value back for their items. They partner with <span className="fw-semibold"><a href='https://thelocalflea.com/' className="styled-link" target="_blank" rel="noopener noreferrer">The Local Flea</a></span>, a consignment store with a large selection of quality, pre-owned items, to get their customers' items resold.<br></br><br></br>As a Design Intern, I was tasked with a wide variety of projects, many of which had the goal of expanding the company's SEO strategy and improving connections with partners.</p>


                    <div className='d-flex justify-content-between flex-wrap pt-4 gap-5'>
                        <div>
                            <p className='text-uppercase fw-semibold'>Skills</p>
                            <ul>
                                <li>Prototyping</li>
                                <li>Marketing graphic design</li>
                            </ul>
                        </div>
                        <div>
                            <p className='text-uppercase fw-semibold'>Tools</p>
                            <ul>
                                <li>Figma</li>
                                <li>Illustrator</li>
                                <li>InDesign</li>
                                <li>Canva</li>
                            </ul>
                        </div>
                    </div>

                </div>

                <div className='card-container rounded-corner'>
                    <h1 className='display-2 
                     text-center'>

                        The Product Style Guide

                        {/*                      <FontAwesomeIcon icon={faStar} className='secondaryColor header-icon' style={{}} /> */}
                    </h1>

                    <div className=" d-flex lighter-bg flex-wrap justify-content-around gap-4 mt-4 px-4">

                        <img src={process.env.PUBLIC_URL + "/images/remoov-TLF-PCExample-1.png"} alt="" className="flex-third"></img>
                        <img src={process.env.PUBLIC_URL + "/images/remoov-TLF-PCExample-2.png"} alt="" className="flex-third"></img>
                        <img src={process.env.PUBLIC_URL + "/images/remoov-TLF-PCExample-3.png"} alt="" className="flex-third "></img>

                    </div>

                    <div className="d-flex flex-wrap-reverse justify-content-between align-items-end mt-5 gap-4">
                        <img src={process.env.PUBLIC_URL + "/images/remoov-TLF-PCTemplate.png"} className='flex-half' alt=""></img>
                    <div className="flex-half">
                    <p className='text-uppercase fw-semibold secondaryColor '>Primary Goals</p>
                        <p>The "Product Style Guide" is a category of webpages to be deployed on The Local Flea's website. The main goals of this project are to:</p>

                        <div className="flex-list">

                        <div className='flex-list-item'>
                            <div className='circle-number'>1</div>
                            <span>Inform readers of the best ways to style their furniture items within their space.</span>
                        </div>
                        <div className='flex-list-item'>
                            <div className='circle-number'>2</div>
                            <span>Drive organic traffic to The Local Flea's website.</span>
                        </div>
                        <div className='flex-list-item'>
                            <div className='circle-number'>3</div>
                            <span>Increase users' session durations, or time spent on the website.</span>
                        </div>
                    </div>
                        <p className='mt-2'>
                        As the designer, I was tasked with prototyping the pages and coming up with <span className="fw-semibold">a workflow that would allow for quick turnaround</span>.
                        </p>
                        <p className='text-uppercase fw-semibold secondaryColor mt-4'>Designing the Template</p>
                        <p>Because we knew from the start that dozens of these pages would eventually be launched on the website, I proposed creating a template that would make designing these pages more efficient for the designer, copywriter, and engineers. This template was created using Figma components, which will simplify project handoff and prevent deviations from the established layout.</p>
                        <p className='text-uppercase fw-semibold secondaryColor mt-4'>Positive Impact</p>
                        <p>Since the creation of this template, over 30 copies have been written, and I've designed <span className='fw-semibold'>over a dozen Product Style Guide pages</span>. Using the template sped up the design process by more than 50%.</p>
                    </div>
                </div>
                </div>

                <div className='card-container rounded-corner'>
                    <h1 className='display-2 
                     text-center'>

                        The Welcome Kit

                        {/*                      <FontAwesomeIcon icon={faStar} className='secondaryColor header-icon' style={{}} /> */}
                    </h1>


                    <div className='d-flex flex-wrap-reverse justify-content-between align-items-end mt-4 gap-4'>
                    <div className="flex-half">
                    <Stack direction="vertical" gap={4}>
                        <img src={process.env.PUBLIC_URL + "/images/remoov-welcome-kit-1.png"} alt="" className="rounded-corner-inner"></img>
                        <img src={process.env.PUBLIC_URL + "/images/remoov-welcome-kit-2.png"} alt="" className="rounded-corner-inner"></img>
                        <img src={process.env.PUBLIC_URL + "/images/remoov-welcome-kit-3.png"} alt="" className="rounded-corner-inner"></img>
                        </Stack>
                    </div>
                    <div className="flex-half">
                    <p className='text-uppercase fw-semibold secondaryColor'>Primary Goals</p>
                        <p>The project to create a welcome kit for partners was launched to demonstrate Remoov's commitment to the partnership and provide a way to introduce partners to the company. It includes advice and tips for informing their clients of Remoov's services and links to access digital files for marketing.<br></br><br></br>As the designer, I was tasked with visually presenting a wide variety of in-depth information in a cohesive and easily understandable way.</p>
                        <p className='text-uppercase fw-semibold secondaryColor mt-4'>Iterative Designing</p>
                        <p>During the design process, I collaborated closely with our team's copywriter and the CEO to be certain that the material accurately represented the company and its values. With an abundance of feedback, I iterated on the original design close to a dozen times to arrive at the final result.</p>
                    </div>

                </div>

                </div>

                <div className='card-container rounded-corner'>
                    <h1 className='display-2 
                     text-center'>

                        Miscellaneous Design Projects

                        {/*                      <FontAwesomeIcon icon={faStar} className='secondaryColor header-icon' style={{}} /> */}
                    </h1>


                    <Stack direction="vertical"className=''>

                <div className="d-flex flex-wrap justify-content-between align-items-end mt-4 gap-4">
            <img src={process.env.PUBLIC_URL + "/images/thumb-remoov.png"} alt="" className="rounded-corner-inner flex-half"></img>
            <img src={process.env.PUBLIC_URL + "/images/remoov-brochure-inner.png"} alt="" className="rounded-corner-inner flex-half"></img>
            </div>
            
            <div className="d-flex flex-wrap justify-content-between align-items-end mt-4 gap-4">
            <img src={process.env.PUBLIC_URL + "/images/remoov-card-1.png"} alt="" className="rounded-corner-inner flex-half"></img>
            <img src={process.env.PUBLIC_URL + "/images/remoov-card-2.png"} alt="" className="rounded-corner-inner flex-half"></img>
            </div>


            <div className="d-flex flex-wrap justify-content-between align-items-end mt-4 gap-4">
            <img src={process.env.PUBLIC_URL + "/images/remoov-flyer-2.png"} alt="" className="rounded-corner-inner flex-half"></img>
            <img src={process.env.PUBLIC_URL + "/images/remoov-flyer-1.png"} alt="" className="rounded-corner-inner flex-half"></img>
            </div>
            </Stack>

            </div>
            </Fade>



            </Stack>
        </div>

    );
}

export default Remoov;
import '../Nbar.css';
import Button from 'react-bootstrap/Button';
import { NavLink } from "react-router-dom";
import Image from 'react-bootstrap/Image';




//import { NavLink } from "react-router-dom";

export default function Nbar() {
  return (

    <div className="Nbar card-container rounded-corner" >
          <div className='d-flex flex-wrap align-items-center justify-content-between'>
            
            <NavLink to="/" className='logo-disappear-on-resize'>
      <span className='secondaryFont display-3'>Sarah</span>
      </NavLink>

      
      
      <div className='d-flex gap-1 justify-content-between align-items-center flex-grow-on-resize'>

<NavLink to="/">
  

<Button variant="outline-primary" size="sm" className='rounded-pill custom-button-outline'>Work</Button>
</NavLink>

<Image src={process.env.PUBLIC_URL + "/images/star-twinkle-outline-black.svg"} alt='' style={{height:'1.5rem'}}className='' />


<NavLink to="/play">
<Button variant="outline-primary" size="sm" className='rounded-pill custom-button-outline'>Play </Button>
</NavLink>

<Image src={process.env.PUBLIC_URL + "/images/star-twinkle-outline-black.svg"} alt='' style={{width:'1.5rem'}}className='' />

<a href={process.env.PUBLIC_URL + "/SarahWoo_resume.pdf"}>
<Button variant="outline-primary" size="sm" className='rounded-pill custom-button-outline'>Resume</Button>
</a>


</div>

</div>
    </div>

  );
}
import '../Work.css';
import Nbar from "../components/Nbar";

import Stack from 'react-bootstrap/Stack';
import { Fade} from "react-awesome-reveal";


function Play() {
    return (
        <div className="projectPage">

            <Stack direction="vertical" gap={4}>
                <Fade>

                <Nbar />

                <div className='card-container rounded-corner'>
                    <h1 className='display-2 
                     text-center'>

                        Place

                        {/*                      <FontAwesomeIcon icon={faStar} className='secondaryColor header-icon' style={{}} /> */}
                    </h1>
                    <p className='mt-4'>An experience of two contrasting environments and the interactive journey between them, created in VR using Tiltbrush, Unity, VRChat, and an Oculus Quest.
                    </p>


                    
                <div className="d-flex flex-wrap justify-content-between align-items-end mt-4 gap-4">
            <img src={process.env.PUBLIC_URL + "/images/play-VR-desert.gif"} alt="" className="rounded-corner-inner flex-half"></img>
            <img src={process.env.PUBLIC_URL + "/images/play-VR-forest.gif"} alt="" className="rounded-corner-inner flex-half"></img>
            </div>
            
            
            </div>

            <div className='card-container rounded-corner'>
                    <h1 className='display-2 
                     text-center'>

                        Oasis

                        {/*                      <FontAwesomeIcon icon={faStar} className='secondaryColor header-icon' style={{}} /> */}
                    </h1>
                    <p className='mt-4'>A dreamy, surreal landscape, inspired by my ideal resting place, modeled and animated completely in Maya with post effects and video editing in Adobe After Effects. Inspired by the works of <a href=" https://www.instagram.com/blakekathryn/?hl=en" className="styled-link" target="_blank" rel="noopener noreferrer">Blake Kathryn</a>.


                    </p>


                    
                
            <img src={process.env.PUBLIC_URL + "/images/play-oasis.gif"} alt="" className="rounded-corner-inner mx-auto mt-4"></img>

            
            
            </div>

            <div className='card-container rounded-corner'>
                    <h1 className='display-2 
                     text-center'>

                        Emerging Forms II

                        {/*                      <FontAwesomeIcon icon={faStar} className='secondaryColor header-icon' style={{}} /> */}
                    </h1>
                    <p className='mt-4'>Two of four oil paintings in a series called Emerging Forms II. Through these studies, I sought to portray infinitely large spaces that could be found within the finitely small overlapping forms in the <a href=" https://www.audubon.org/magazine/july-august-2015/skeleton-crew-bones-and-bodies-behind-risds" className="styled-link">Skeleton Room at RISD's Nature Lab</a>.
                    </p>


                    
                <div className="d-flex flex-wrap justify-content-between align-items-center mt-4 gap-4">
            <img src={process.env.PUBLIC_URL + "/images/play-EF-I.jpg"} alt="" className="rounded-corner-inner flex-half"></img>
            <img src={process.env.PUBLIC_URL + "/images/play-EF-II.jpg"} alt="" className="rounded-corner-inner flex-half"></img>
            </div>
            
            
            </div>
                </Fade>



</Stack>
</div>

);
}

export default Play;
import '../Work.css';
import Nbar from "../components/Nbar";



import Image from 'react-bootstrap/Image';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import { Fade} from "react-awesome-reveal";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar} from '@fortawesome/free-regular-svg-icons'
import { faTabletScreenButton} from '@fortawesome/free-solid-svg-icons'


function Kiko() {
    return (
        <div className="projectPage">

            <Stack direction="vertical" gap={4}>
                <Fade>

                <Nbar />

                <div className='card-container rounded-corner text-center'>
                    <p className='text-uppercase fw-semibold secondaryColor'>Ki-Ko</p>
                    <span className='display-2 fw-medium '>Bringing the Internet's menu to your doorstep.</span>

                    <Stack direction="horizontal" gap={2} className='badge-container justify-content-center mt-2'>
                        <Badge pill bg="" className='fw-medium custom-badge-outline'>
                            UI Design
                        </Badge>
                        <Badge pill bg="" className='fw-medium custom-badge-outline'>
                            Branding
                        </Badge>

                    </Stack>


                    <Image src={process.env.PUBLIC_URL + "/images/thumb-kiko.jpg"} className='rounded-corner-inner mt-3' alt='' />

                </div>

                <div className='card-container rounded-corner'>
                    <p className='text-uppercase fw-semibold'>Overview</p>

                    <p><span className='fw-semibold'>Ki-Ko aims to bring together the world of content creators, local restaurants, and foodie Internet users to make food on social media readily accessible.</span> After working directly with the founder to clarify Ki-Ko's mission and business goals, I defined the product requirements to design an MVP and established a warm and inviting visual design system.
                    </p>
                    <p>Currently, I am in the process of designing the restaurant facing UI that will be used by the restaurants in Ki-Ko's network.</p>
                    


                    <div className='d-flex justify-content-between flex-wrap pt-4 gap-5'>
                        <div>
                            <p className='text-uppercase fw-semibold'>Skills</p>
                            <ul>
                                <li>UI design</li>
                                <li>Branding</li>
                            </ul>
                        </div>

                        <div>
                            <p className='text-uppercase fw-semibold'>Tools</p>
                            <ul>
                                <li>Figma</li>
                            </ul>
                        </div>

                    </div>

                </div>

                <div className='card-container rounded-corner'>
                <h1 className='display-2 
                     text-center'>

                        The Design

                        <FontAwesomeIcon icon={faStar} className='secondaryColor header-icon' style={{}} />
                    </h1>

                    <Image src={process.env.PUBLIC_URL + "/images/kiko-final.jpg"} className='rounded-corner-inner mt-4' alt='' />

             


                </div>



                <div className='card-container rounded-corner'>
                    <h1 className='display-2  text-center'>
Next Steps: Clover Tablet

                        <FontAwesomeIcon icon={faTabletScreenButton} className='secondaryColor header-icon' style={{}} />
                    </h1>

                    <p className='mt-4'>Designing the restaurant facing UI presented a whole new set of challenges. In contrast to the mobile product that was designed for customers, this interface must allow restaurants to <span className='fw-semibold'>add and edit items in their menu, access recipes for menu items, keep track of orders, and understand trends in how customers engage with the services they provide for Ki-Ko to make decisions that will help their business</span>. This interface is being designed while also considering the physical environment in which it might be used, where employees are likely to be on-the-go and need content to be easily readable and quickly understood.
                    
                    </p>

                    <Image src={process.env.PUBLIC_URL + "/images/kiko-clover.jpg"} className='rounded-corner-inner mt-4' alt='' />

                </div>

                </Fade>

                

            </Stack>
        </div>

    );
}

export default Kiko;
import '../Work.css';
import Nbar from "../components/Nbar";


import Image from 'react-bootstrap/Image';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import { Fade} from "react-awesome-reveal";



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faLightbulb } from '@fortawesome/free-regular-svg-icons'
import { faTabletScreenButton, faPalette} from '@fortawesome/free-solid-svg-icons'





function Cusdom() {
    return (
        <div className="projectPage">
            <Stack direction="vertical" gap={4}>
            <Fade>

                <Nbar />
                <div className='card-container rounded-corner text-center'>
                    <p className='text-uppercase fw-semibold secondaryColor'>Cusdom Design Agency</p>
                    <span className='display-2 fw-medium '>Defining an up-and-coming design agency's look.</span>

                    <Stack direction="horizontal" gap={2} className='badge-container justify-content-center mt-2'>
                        <Badge pill bg="" className='fw-medium custom-badge-outline'>
                            UI Design
                        </Badge>
                        <Badge pill bg="" className='fw-medium custom-badge-outline'>
                            Branding
                        </Badge>
                        <Badge pill bg="" className='fw-medium custom-badge-outline'>
                            Development
                        </Badge>

                    </Stack>


                    <Image src={process.env.PUBLIC_URL + "/images/thumb-cusdom.png"} className='rounded-corner-inner mt-3' alt='' />

                </div>

                <div className='card-container rounded-corner'>
                    <p className='text-uppercase fw-semibold'>Overview</p>
                    <p><a href="https://www.gocusdom.com" className="styled-link" target="_blank" rel="noopener noreferrer">Cusdom Agency</a> is a design and development agency dedicated to creating high-quality designs and custom software for startups, small business owners, and creatives.

                        Working closely with the company's CEO and primary developer, I completely redesiged the company's website and establish a visual design system that reflects an <span className='fw-semibold'>inviting sense of professionalism.</span> </p>


                    <div className='d-flex justify-content-between flex-wrap pt-4 gap-5'>
                        <div>
                            <p className='text-uppercase fw-semibold'>Skills</p>
                            <ul>
                                <li>UI design</li>
                                <li>Development</li>
                                <li>Graphic design</li>
                            </ul>
                        </div>

                        <div>
                            <p className='text-uppercase fw-semibold'>Tools</p>
                            <ul>
                                <li>Figma</li>
                                <li>Webflow</li>
                            </ul>
                        </div>

                    </div>

                </div>

                <div className='card-container rounded-corner'>
                <h1 className='display-2 
                     text-center'>

                        The Final Design

                        <FontAwesomeIcon icon={faStar} className='secondaryColor header-icon' style={{}} />
                    </h1>

                    <Image src={process.env.PUBLIC_URL + "/images/cusdom_final.jpg"} className='rounded-corner-inner mt-4' alt='' />

             


                </div>


                <div className='card-container rounded-corner'>
                <h1 className='display-2 
                     text-center'>

                        The Design System

                        <FontAwesomeIcon icon={faPalette} className='secondaryColor header-icon' style={{}} />
                    </h1>

                    <Image src={process.env.PUBLIC_URL + "/images/cusdom-visual-design.jpg"} className='rounded-corner-inner mt-4' alt='' />

                </div>


                <div className='card-container rounded-corner text-center'>
                    <h1 className='display-2  text-center'>

                        Responsive Design

                        <FontAwesomeIcon icon={faTabletScreenButton} className='secondaryColor header-icon' style={{}} />
                    </h1>

                    <div className='d-flex flex-wrap justify-content-between'>
                    <Image src={process.env.PUBLIC_URL + "/images/cusdom_responsive-1.png"} className='rounded-corner-inner mx-auto flex-half' alt='' />
                    <Image src={process.env.PUBLIC_URL + "/images/cusdom_responsive-2.png"} className='rounded-corner-inner mx-auto flex-half' alt='' />
                    </div>
                    

                </div>

                <div className='card-container rounded-corner'>
                    <h1 className='display-2  text-center'>


                        Key Takeaways

                        <FontAwesomeIcon icon={faLightbulb} className='secondaryColor header-icon' style={{}} />
                    </h1>

                    <p className='mt-4'><span className='fw-semibold '>Always be learning.</span> This web design project was my first experience with working in Webflow. It was so exciting to pick up a new skill and play a big role in bringing the final design to life.
                    
                    </p>
                        <p><span className='fw-semibold'>Handoff considerations.</span> Being responsible for both the design and development phase reaffirmed just how essential it is to consider the developer's perspective during the design process and intentionally consider what has to be communicated and what may or may not be feasible in the given timeframe.
                        </p>
                    </div>
                    </Fade>

            </Stack>
        </div>

    );
}

export default Cusdom;
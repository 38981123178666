import '../Work.css';
import Nbar from "../components/Nbar";



import Image from 'react-bootstrap/Image';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import { Fade } from "react-awesome-reveal";



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-regular-svg-icons'
import { faAnglesRight, faStethoscope } from '@fortawesome/free-solid-svg-icons'





function Healiom() {
    return (
        <div className="projectPage">

            <Stack direction="vertical" gap={4}>
                <Fade>


                    <Nbar />
                    <div className='card-container rounded-corner text-center'>
                        <p className='text-uppercase fw-semibold secondaryColor '>Healiom</p>
                        <span className='display-2 fw-medium '>Scaling medical capacity to make receiving and giving care easy.</span>

                        <Stack direction="horizontal" gap={2} className='badge-container justify-content-center mt-2'>
                            <Badge pill bg="" className='fw-medium custom-badge-outline'>
                                User Research
                            </Badge>
                            <Badge pill bg="" className='fw-medium custom-badge-outline'>
                                UX Design
                            </Badge>
                            <Badge pill bg="" className='fw-medium custom-badge-outline'>
                                UI Design
                            </Badge>

                        </Stack>





                        <Image src={process.env.PUBLIC_URL + "/images/healiom-thumb.jpg"} className='rounded-corner-inner mt-3' alt='' />
                    </div>


                    <div className='card-container rounded-corner'>
                        <p className='text-uppercase fw-semibold'>Overview</p>
                        <p>
                            <a href="https://www.healiom.com/" className="styled-link" target="_blank" rel="noreferrer">Healiom</a> leverages the power of AI and the flexibility of remote appointments to improve clinical quality and task efficiency. The company's core product strives to improve upon widely used electronic health record (EHR) systems by alleviating common frustrations. <br></br><br></br>

                            As a UI/UX Design Intern at Healiom, I was tasked with redesigning the primary screen of this product, the <span className='fw-semibold'>patient-provider consult trifold</span>. The consult trifold is the contact point between patients and physicians and should provide a way for physicians to interface with patients via a video call while accessing the patient's clinical history to inform their encounter notes.<br></br><br></br>


                        </p>




                        <div className='d-flex justify-content-between flex-wrap pt-4 gap-5'>
                            <div>
                                <p className='text-uppercase fw-semibold'>Team</p>
                                <ul>
                                    <li>Nina Chang</li>
                                    <li>Aime Menendez</li>
                                    <li>Kevin Park</li>
                                    <li>Sarah Woo</li>
                                </ul>
                            </div>
                            <div>
                                <p className='text-uppercase fw-semibold'>Skills</p>
                                <ul>
                                    <li>User research</li>
                                    <li>Competitor analysis</li>
                                    <li>UX design</li>
                                    <li>UI design</li>
                                </ul>
                            </div>

                            <div>
                                <p className='text-uppercase fw-semibold'>Tools</p>
                                <ul>
                                    <li>Slack</li>
                                    <li>Jira</li>
                                    <li>Figma</li>
                                </ul>
                            </div>

                        </div>

                    </div>
                    <div className='card-container rounded-corner'>
                        <h1 className='display-2 
                     text-center'>

                            Understanding Physicians' Needs

                            <FontAwesomeIcon icon={faStethoscope} className='secondaryColor header-icon' style={{}} />
                        </h1>




                        <div className='mt-5'>
                            <p>

                                The design process involved constant conversations with Healiom's <span className='fw-semibold'>Chief Medical Officer (CMO), Sean Howse</span> to understand physicians' needs in practice. From these conversations, I gathered that:
                            </p>
                            <div className="flex-list">

                                <div className='flex-list-item'>
                                    <div className='circle-number'>1</div>
                                    <span>Physicians spend too much time on writeups and not enough time seeing more patients.</span>
                                </div>
                                <div className='flex-list-item'>
                                    <div className='circle-number'>2</div>
                                    <span>The writeup process feels tedious and unenjoyable.</span>
                                </div>
                                <div className='flex-list-item'>
                                    <div className='circle-number'>3</div>
                                    <span>To provide effective care, physicians must be able to understand and reference both the patient's clinical history and current condition.</span>
                                </div>
                            </div>

                            {/* 


                                Physicians want to be able to

                                perform writeups quickly
                                what would this require? minimizing the number of clicks it takes to perform a task, quickly placing relevant components and information in the UI

                                in general, they want to be able to see as many patients as possible, which means spending the minimal amount of time needed on typing up notes. providers spending too much time on administrative tasks contributes to the culture of overwork and shortage of providers


                                understanding patient data/vitals/history IN CONTEXT

                                SOAP note structure

                                collapsible actions
                                cross reference history with current encounter notes (need to be visible on same screen, reduces clicks)




                                having video in center of screen to avoid situation like when physician just stares at desktop while they talk to you (impersonal)
                                SOAP note structure
                                may need to observe patient visually: movable video, collapsible actions
                                cross reference history with current encounter notes (need to be visible on same screen, reduces clicks)
                                build trust in AI: live transcript, ability to turn down AI suggestions)

                                The challenge: presenting a tonnnn of information on a single screen, and making it easy and intuitive to find information!! Cerner fails to do this in an effective way.


                                issues with current UI: designed without user experience in mind; functional but not pleasant or easy to use; throws a ton of information at the user without considering visual hierarchy, prioritization of information, readability
 */}
                        </div>
                    </div>

                    <div className='card-container rounded-corner'>
                        <h1 className='display-2 
                     text-center'>

                            The Industry Standard


                        </h1>



                        <div className="
                        d-flex flex-wrap justify-content-between align-items-start mt-5 gap-4">
                            <div className='flex-half'>
                                <p className='text-uppercase fw-semibold secondaryColor'>Oracle Cerner</p>
                                <p className=''>To gain a better understanding of the typical provider workflow, I studied <a href="https://www.youtube.com/watch?v=3uwLAM2eFSI" className="styled-link" target="_blank" rel="noreferrer">walkthroughs</a> of the current UI of <span className="fw-semibold">Oracle Cerner</span>, one of the most widely used EHRs in the medical industry. Just a glance at the screen reveals that <span className='fw-semibold'>the user interface is extremely overwhelming</span>. Loads of information are thrown at the user, and the location of needed information is often unintuitive. In cases where patients have a lot of clinical history and data that providers have to sort through, this experience becomes a time-consuming nightmare.


                                </p>


                            </div>

                            
                                <img src={process.env.PUBLIC_URL + "/images/healiom-cerner.png"} alt="" className="rounded-corner-inner mx-auto flex-half"></img>
                            
                        </div>

                    </div>












                    <div className='card-container rounded-corner'>
                        <h1 className='display-2 
                     text-center'>

                            The Final Solution

                            <FontAwesomeIcon icon={faStar} className='secondaryColor header-icon' style={{}} />
                        </h1>



                        <div className='rounded-corner-inner mt-4' style={{ overflow: "hidden" }}>
                            <Image src={process.env.PUBLIC_URL + "/images/healiom-final-1.jpg"} className='' alt='' />
                            <Image src={process.env.PUBLIC_URL + "/images/healiom-final-2.jpg"} className='' alt='' />
                            <Image src={process.env.PUBLIC_URL + "/images/healiom-final-3.jpg"} className='' alt='' />
                            <Image src={process.env.PUBLIC_URL + "/images/healiom-final-3.gif"} className='' alt='' style={{ width: "100%" }} />
                            <Image src={process.env.PUBLIC_URL + "/images/healiom-final-4.jpg"} className='' alt='' />
                            <Image src={process.env.PUBLIC_URL + "/images/healiom-final-5.jpg"} className='' alt='' />
                            <Image src={process.env.PUBLIC_URL + "/images/healiom-final-6.jpg"} className='' alt='' />
                        </div>


                    </div>


                    <div className='card-container rounded-corner'>
                        <h1 className='display-2  text-center'>


                            Next Steps

                            <FontAwesomeIcon icon={faAnglesRight} className='secondaryColor header-icon' style={{}} />
                        </h1>

                        <p className='mt-4'><span className='fw-semibold '>User testing across different types of medical providers</span> will provide insights into the limitations of the above design. This will allow us to design a product that is flexible enough to be used by a wide variety of providers while preserving its usability.</p>
                        <p><span className='fw-semibold'>Motion design</span> will play an important role in characterizing our AI capabilities. Details such as how text is populated into the interface, or animations that indicate that information is being analyzed can convey to the user that the product is working hard behind the scenes to generate helpful and accurate results.
                        </p>
                    </div>

                </Fade>

            </Stack>
        </div>


    );
}

export default Healiom;
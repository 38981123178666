import 'bootstrap/dist/css/bootstrap.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


import './index.css';
import './Work.css';

import ScrollToTop from "./ScrollToTop";

import Work from "./pages/Work.js";
import Play from "./pages/Play.js";
import Healiom from "./pages/Healiom.js";
import Kiko from "./pages/Kiko.js";
import Vital from "./pages/Vital.js";
import Cusdom from "./pages/Cusdom.js";
import Remoov from "./pages/Remoov.js";




import reportWebVitals from './reportWebVitals';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
    <ScrollToTop>
      <Routes>
        <Route path='/' element={<Work />} />
        <Route path='/play' element={<Play />} />
        <Route path='/Healiom' element={<Healiom />} />
        <Route path='/Kiko' element={<Kiko />} />
        <Route path='/Vital' element={<Vital />} />
        <Route path='/Cusdom' element={<Cusdom />} />
        <Route path='/Remoov' element={<Remoov />} />
      </Routes>
      </ScrollToTop>
    </Router>


  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

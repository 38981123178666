import '../Work.css';
import Nbar from "../components/Nbar";
import Footer from "../components/Footer";


import { NavLink } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import { Fade } from "react-awesome-reveal";




import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'



import ReactCurvedText from "react-curved-text";


function Work() {
  return (
    <div className="work">


      <Stack direction="vertical" gap={4}>
        <Fade fraction='0.15'>
          <Nbar />

          <div className='d-flex flex-wrap gap-4'>


            <div className="card-container rounded-corner card-home-left">
              <div className='d-flex flex-nowrap justify-content-between gap-3'>
                <div className=''>
                  <span className='display-2'>Hi, m<span className='secondaryFont'>y nam</span>e is</span><br></br>
                  <span className='display-1 secondaryFont'>Sarah<Image src={process.env.PUBLIC_URL + "/images/star-multi-gradient.svg"} alt='' style={{width:'4rem', display:'inline', marginTop:'-1rem', marginLeft:'0.5rem', zIndex:-1}}className='' /></span>
                </div>


              </div>

              <span className='display-2'>I'm a v<span className='secondaryFont'>isual</span> design<span className='secondaryFont'>er</span> specia<span className='secondaryFont'>lizing</span> in <span className='gradient-text fw-medium' style= {{whiteSpace: 'nowrap'}}>UI <span className='secondaryFont'>&</span> UX design</span>.</span><br></br><br></br>
              <a href="mailto:jihyunsarahwoo@gmail.com" target="_blank" rel="noopener noreferrer">
                <Button variant="outline-primary" size="lg" className='rounded-pill custom-button-outline'><FontAwesomeIcon icon={faEnvelope} bounce style={{ paddingRight: '0.6rem' }} />Let's get in touch!</Button></a>
            </div>



            <div className="card-container rounded-corner card-home-right align-items-center">

              <Image src={process.env.PUBLIC_URL + "/images/profile-photo_.png"} alt='' className='rounded-circle' />



            </div>

          </div>


          <NavLink to='/Healiom'><div className='card-container-link rounded-corner'>
            <div className='d-flex flex-wrap gap-4'>
              <Image src={process.env.PUBLIC_URL + "/images/thumb-healiom.jpg"} className='rounded-corner-inner card-image' style={{ alignSelf: 'start' }} alt='' />

              <div className='card-text'>
                <span className='display-2 fw-medium'>Scaling medical capacity to make receiving and giving care easy.</span>
                <Stack direction="horizontal" gap={2} className='badge-container'>
                  <Badge pill bg="" className='fw-medium custom-badge-outline'>
                    User Research
                  </Badge>
                  <Badge pill bg="" className='fw-medium custom-badge-outline'>
                    UX Design
                  </Badge>
                  <Badge pill bg="" className='fw-medium custom-badge-outline'>
                    UI Design
                  </Badge>

                </Stack>

                <div className='d-flex flex-wrap'><span className='text-uppercase fw-semibold' style={{ paddingRight: "0.75rem" }}>Healiom</span><span className='fst-italic fw-light'>November 2023 - Present</span></div>
                <div className='read-more'>
                  <ReactCurvedText
                    width={100}
                    height={100}
                    cx={50}
                    cy={50}
                    rx={35}
                    ry={35}
                    startOffset={21}
                    reversed={true}
                    text="read more about this project"
                    textProps={{ style: { fontSize: '1rem', fill: '#FF6B00', fontFamily: 'Windstone Serif' } }}
                    textPathProps={null}
                    tspanProps={null}
                    ellipseProps={null}
                    svgProps={null}
                  />
                  <FontAwesomeIcon icon={faArrowRight} style={{}} bounce className='read-more-arrow secondaryColor' />

                </div>


              </div>


            </div>





          </div>
          </NavLink>



          <NavLink to='/Vital'><div className='card-container-link rounded-corner'>
            <div className='d-flex flex-wrap gap-4'>
              <Image src={process.env.PUBLIC_URL + "/images/thumb-vital.jpg"} className='rounded-corner-inner card-image' style={{ alignSelf: 'start' }} alt='' />

              <div className='card-text'>
                <span className='display-2 fw-medium'>Reimagining at-home fitness for the visually impaired.</span>
                <Stack direction="horizontal" gap={2} className='badge-container'>
                  <Badge pill bg="" className='fw-medium custom-badge-outline'>
                    User Research
                  </Badge>
                  <Badge pill bg="" className='fw-medium custom-badge-outline'>
                    UI Design
                  </Badge>
                  <Badge pill bg="" className='fw-medium custom-badge-outline'>
                    Branding
                  </Badge>

                </Stack>

                <div className='d-flex flex-wrap'><span className='text-uppercase fw-semibold' style={{ paddingRight: "0.75rem" }}>Vital Fitness</span><span className='fst-italic fw-light'>March 2023</span></div>
                <div className='read-more'>
                  <ReactCurvedText
                    width={100}
                    height={100}
                    cx={50}
                    cy={50}
                    rx={35}
                    ry={35}
                    startOffset={21}
                    reversed={true}
                    text="read more about this project"
                    textProps={{ style: { fontSize: '1rem', fill: '#FF6B00', fontFamily: 'Windstone Serif' } }}
                    textPathProps={null}
                    tspanProps={null}
                    ellipseProps={null}
                    svgProps={null}
                  />
                  <FontAwesomeIcon icon={faArrowRight} style={{}} bounce className='read-more-arrow secondaryColor' />

                </div>


              </div>


            </div>





          </div>
          </NavLink>







          {/* <NavLink to='/Drugworks'><div className='card-container-link rounded-corner'>
          <div className='d-flex flex-wrap gap-4'>
          <Image src={process.env.PUBLIC_URL + "/images/thumb-drugworks.png"} className='rounded-corner-inner card-image' style={{alignSelf:'start'}} alt='' />

          <div className='card-text'>
          <span className='display-2 fw-medium'>App design for a customer-first cafe/pharmacy business.</span>
              <Stack direction="horizontal" gap={2} className='badge-container'>
              <Badge pill bg="" className='fw-medium custom-badge-outline'>
                  User Research
                </Badge>
                <Badge pill bg="" className='fw-medium custom-badge-outline'>
                  UI Design
                </Badge>
                <Badge pill bg="" className='fw-medium custom-badge-outline'>
                  Branding
                </Badge>

              </Stack>

              <div className='d-flex flex-wrap'><span className='text-uppercase fw-semibold' style={{ paddingRight: "0.75rem" }}>Drugworks Cafe</span><span className='fst-italic fw-light'>January 2023</span></div>
              <div className='read-more'>
              <ReactCurvedText
                  width={100}
                  height={100}
                  cx={50}
                  cy={50}
                  rx={35}
                  ry={35}
                  startOffset={21}
                  reversed={true}
                  text="read more about this project"
                  textProps={{ style: { fontSize: '1rem', fill: '#FF6B00', fontFamily: 'Windstone Serif'} }}
                  textPathProps={null}
                  tspanProps={null}
                  ellipseProps={null}
                  svgProps={null}
                />
                <FontAwesomeIcon icon={faArrowRight} style={{}} className='read-more-arrow secondaryColor' />

              </div>


          </div>

            
          </div>

          



        </div>
        </NavLink> */}

          <NavLink to='/Kiko'><div className='card-container-link rounded-corner'>
            <div className='d-flex flex-wrap gap-4'>
              <Image src={process.env.PUBLIC_URL + "/images/thumb-kiko.jpg"} className='rounded-corner-inner card-image' style={{ alignSelf: 'start' }} alt='' />

              <div className='card-text'>
                <span className='display-2 fw-medium'>Bringing the Internet's menu to your doorstep.</span>
                <Stack direction="horizontal" gap={2} className='badge-container'>
                  <Badge pill bg="" className='fw-medium custom-badge-outline'>
                    UI Design
                  </Badge>
                  <Badge pill bg="" className='fw-medium custom-badge-outline'>
                    Branding
                  </Badge>

                </Stack>

                <div className='d-flex flex-wrap'><span className='text-uppercase fw-semibold' style={{ paddingRight: "0.75rem" }}>Ki-Ko</span><span className='fst-italic fw-light'>April 2023 - Present</span></div>
                <div className='read-more'>
                  <ReactCurvedText
                    width={100}
                    height={100}
                    cx={50}
                    cy={50}
                    rx={35}
                    ry={35}
                    startOffset={21}
                    reversed={true}
                    text="read more about this project"
                    textProps={{ style: { fontSize: '1rem', fill: '#FF6B00', fontFamily: 'Windstone Serif' } }}
                    textPathProps={null}
                    tspanProps={null}
                    ellipseProps={null}
                    svgProps={null}
                  />
                  <FontAwesomeIcon icon={faArrowRight} style={{}} bounce className='read-more-arrow secondaryColor' />

                </div>


              </div>


            </div>





          </div>
          </NavLink>


          <NavLink to='/Cusdom'><div className='card-container-link rounded-corner'>
            <div className='d-flex flex-wrap gap-4'>
              <Image src={process.env.PUBLIC_URL + "/images/thumb-cusdom.png"} className='rounded-corner-inner card-image' style={{ alignSelf: 'start' }} alt='' />

              <div className='card-text'>
                <span className='display-2 fw-medium'>Defining an up-and-coming design agency's look.</span>
                <Stack direction="horizontal" gap={2} className='badge-container'>
                  <Badge pill bg="" className='fw-medium custom-badge-outline'>
                    UI Design
                  </Badge>
                  <Badge pill bg="" className='fw-medium custom-badge-outline'>
                    Branding
                  </Badge>
                  <Badge pill bg="" className='fw-medium custom-badge-outline'>
                    Development
                  </Badge>

                </Stack>

                <div className='d-flex flex-wrap'><span className='text-uppercase fw-semibold' style={{ paddingRight: "0.75rem" }}>Cusdom Design Agency</span><span className='fst-italic fw-light'>August 2023 - October 2023</span></div>
                <div className='read-more'>
                  <ReactCurvedText
                    width={100}
                    height={100}
                    cx={50}
                    cy={50}
                    rx={35}
                    ry={35}
                    startOffset={21}
                    reversed={true}
                    text="read more about this project"
                    textProps={{ style: { fontSize: '1rem', fill: '#FF6B00', fontFamily: 'Windstone Serif' } }}
                    textPathProps={null}
                    tspanProps={null}
                    ellipseProps={null}
                    svgProps={null}
                  />
                  <FontAwesomeIcon icon={faArrowRight} style={{}} bounce className='read-more-arrow secondaryColor' />

                </div>


              </div>


            </div>





          </div>
          </NavLink>



          <NavLink to='/Remoov'><div className='card-container-link rounded-corner'>
            <div className='d-flex flex-wrap gap-4'>
              <Image src={process.env.PUBLIC_URL + "/images/thumb-remoov.png"} className='rounded-corner-inner card-image' style={{ alignSelf: 'start' }} alt='' />

              <div className='card-text'>
                <span className='display-2 fw-medium'>Designing for a tech-enabled decluttering and reselling service.</span>
                <Stack direction="horizontal" gap={2} className='badge-container'>
                  <Badge pill bg="" className='fw-medium custom-badge-outline'>
                    UI Design
                  </Badge>
                  <Badge pill bg="" className='fw-medium custom-badge-outline'>
                    Graphic Design
                  </Badge>

                </Stack>

                <div className='d-flex flex-wrap'><span className='text-uppercase fw-semibold' style={{ paddingRight: "0.75rem" }}>Remoov</span><span className='fst-italic fw-light'>June 2022 - January 2023</span></div>
                <div className='read-more'>
                  <ReactCurvedText
                    width={100}
                    height={100}
                    cx={50}
                    cy={50}
                    rx={35}
                    ry={35}
                    startOffset={21}
                    reversed={true}
                    text="read more about this project"
                    textProps={{ style: { fontSize: '1rem', fill: '#FF6B00', fontFamily: 'Windstone Serif' } }}
                    textPathProps={null}
                    tspanProps={null}
                    ellipseProps={null}
                    svgProps={null}
                  />
                  <FontAwesomeIcon icon={faArrowRight} style={{}} bounce className='read-more-arrow secondaryColor' />

                </div>


              </div>


            </div>





          </div>
          </NavLink>
          <Footer/>
        </Fade>


      </Stack>
    




    </div>

  );
}

export default Work;
